<!--
 * @Author: your name
 * @Date: 2021-01-15 20:12:04
 * @LastEditTime: 2021-01-19 14:41:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\collaborate\index.vue
-->
<template>
  <div class="theallmsg">
    <navigation :msg="msg"></navigation>
    <van-swipe class="my-swipe" :initial-swipe="inswi" :touchable="false" :loop="false" :show-indicators="false" indicator-color="white">
      <van-swipe-item>
        <img src="../../assets/partner1.png"/>
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../assets/partner2.png"/>
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../assets/partner3.png"/>
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../assets/partner4.png"/>
      </van-swipe-item>
    </van-swipe>
    <button v-show="nextshow" @click="nextpage" class="bton bt">下一页</button>
  </div>
</template>
<script>
export default {
  data(){
    return{
      inswi:0,
      msg:"合作伙伴",
      nextshow:true,
    }
  },
  mounted(){
    
  },
  methods:{
    nextpage(){
      this.inswi = ++this.inswi;
      if(this.inswi == "3"){
        this.nextshow = false;
      }
    }
  },
};
</script>
<style scoped>
.bt{
  width: 60%;
  margin-left: 20%;
  border-radius: .2rem;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
}
img{
  width: 100%;
}
.theallmsg{
  background-color: #ffffff;
  padding-bottom: 1rem;
}
</style>